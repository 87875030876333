import { Injectable, EventEmitter } from '@angular/core';
import { HttpClientWitToken } from '../api/custom-http-client.service';
import { TurfApiService } from '../api/turf.api.service';
import { Subject, Observable } from 'rxjs';

import io from 'socket.io-client';
import { HttpClient } from '@angular/common/http';
import { storageService } from '../api/cookies.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.serviceV2';

@Injectable({
  providedIn: 'root',
})
export class WhiteboardService {
  sharedObj = { token: '', facilityId: '', host: '', organizationId: '' };
  private createWB = new Subject<any>();
  private selectWB = new Subject<any>();
  private selectDt = new Subject<any>();
  private sharedObjChnage = new Subject<any>();
  private wbSuffal = new Subject<any>();
  socketUrl: any;
  private socket;
  host;
  myFacility;

  constructor(
    private httpWithToken: HttpClientWitToken,
    private turfApiService: TurfApiService,
    private httpClient: HttpClient,
    private storageService: storageService,
    private storageServiceV2: StorageService
  ) {
    this.socketUrl = environment.baseUrl;
    this.socket = io(this.socketUrl, {
      transports: ['websocket'],
      upgrade: false,
    });

    this.myFacility = this.storageService.get_beacon_facility();
  }

  getData(endPoint, query): Observable<any> {
    return this.httpClient.get<any>(
      this.turfApiService[endPoint] + '?filter=' + query,
      this.httpWithToken.get_autorization_header()
    );
  }

  deleteData(endPoint, id): Observable<any> {
    return this.httpClient.delete<any>(
      this.turfApiService[endPoint] + '/' + id,
      this.httpWithToken.get_autorization_header()
    );
  }

  updateData(endPoint, id, data): Observable<any> {
    return this.httpClient.put<any>(
      this.turfApiService[endPoint] + '/' + id,
      data,
      this.httpWithToken.get_autorization_header()
    );
  }

  postData(endPoint, data): Observable<any> {
    return this.httpClient.post<any>(
      this.turfApiService[endPoint],
      data,
      this.httpWithToken.get_autorization_header()
    );
  }

  updatePost(endPoint, query, data): Observable<any> {
    return this.httpClient.post<any>(
      this.turfApiService[endPoint] + '?where=' + query,
      data,
      this.httpWithToken.get_autorization_header()
    );
  }

  getCountData(endPoint, query): Observable<any> {
    return this.httpClient.get<any>(
      this.turfApiService[endPoint] + '?where=' + query,
      this.httpWithToken.get_autorization_header()
    );
  }

  getApiUrl() {
    return this.turfApiService.config;
  }

  /* broadcast */
  createWb(value: any = null) {
    this.createWB.next(value);
  }

  get createWbOn$() {
    return this.createWB.asObservable();
  }

  selectWb(event) {
    this.selectWB.next(event);
  }

  get selectWbOn$() {
    return this.selectWB.asObservable();
  }

  selectDate(event) {
    this.selectDt.next(event);
  }

  get selectDateOn$() {
    return this.selectDt.asObservable();
  }

  routeChange(event) {
    this.sharedObjChnage.next(event);
  }

  get routeChangeon$() {
    return this.sharedObjChnage.asObservable();
  }

  startWbSuffal(event) {
    this.wbSuffal.next(event);
  }

  get startWbSuffalOn$() {
    return this.wbSuffal.asObservable();
  }

  /** shared object */
  setSharedObj(param) {
    this.sharedObj = Object.assign({}, param);
  }

  getSharedObj() {
    return this.sharedObj;
  }



  refreshMowing() {
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.socket.on('Mowing:' + this.sharedObj.facilityId, (data) => {
          observer.next(data);
        });
        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  refreshNote() {
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.socket.on('Note:' + this.sharedObj.facilityId, (data) => {
          observer.next(data);
        });
        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  refreshBoard() {
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.socket.on(
          'whiteboardGroup:' + this.sharedObj.facilityId,
          (data) => {
            observer.next(data);
          }
        );
        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  /**
   * When completion of auto clockout
   */

  autoClockoutCompleted() {
    let organizationId = this.storageService.getLoggedInUser()?.organizationId;

    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.socket.on(`organization:clockout:${organizationId}`, (data) =>
          observer.next()
        );
        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  refreshwhiteboardVerifyId() {
    const organizationId = this.storageServiceV2?.user?.organizationId;
    this.sharedObj.organizationId = this.storageService.getLoggedInUser()?.organizationId;
    console.log(
      `organizationId new ${organizationId} and old ${this.sharedObj?.organizationId}`
    );
  }

  refreshwhiteboardV2() {
    console.log("data", `organization:taskworker:${this.storageServiceV2?.user?.organizationId}`)
    // organization:taskworker:65

    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.socketUrl = environment.baseUrl;
        console.log(
          `From Function organizationId new ${this.storageServiceV2?.user?.organizationId} and old ${this.storageServiceV2?.user?.organizationId}`
        );
        let socketNameV2 = `organization:taskworker:${this.storageServiceV2?.user?.organizationId}`;
        this.socket.on(socketNameV2, (data) => {
          console.log('After recieved socket event', data);
          observer.next(data);
          console.log('Refresh socket triggered', data);
        });
        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });
        return () => {
          console.log('Socket Diconnect Block');
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }



  refreshwhiteboard() {
    this.sharedObj.host = this.storageService.get_beacon_host();
    // this.host = this.storageService.get_beacon_host();
    this.sharedObj.facilityId = this.storageService.getLoggedInUser()?.currentFacilityId;
    this.sharedObj.organizationId = this.storageService.getLoggedInUser()?.organizationId;
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.socketUrl = environment.baseUrl;

        this.socket.on(
          `organization:taskworker:${this.sharedObj.organizationId}`,
          (data) => {
            observer.next(data);
          }
        );
        return () => {
          console.log('Socket Diconnect Block');
        };
      }
    );
    return observable;
  }


  refreshwhiteboard1() {
    
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.socketUrl = environment.baseUrl;

        this.socket.on(
          'vishal-socket-testing',
          (data) => {
            observer.next(data);
          }
        );
        return () => {
          console.log('Socket Diconnect Block');
        };
      }
    );
    return observable;
  }


}
